import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Select, Space, notification } from "antd";

import ExportExampleProducts from "./ExportProducts";
import ExportCategoriesAttributes from "./ExportCategories";

import categoriesList from "../CategorySearchBar/categoriesMLB.json";

const ExportMultipleCategories = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mlbs, setMlbs] = useState();
  const [categories, setCategories] = useState({});
  // categories type:
  // {
  //   [mlb]: {
  //     info: { name: "", mlb: "", path: "" },
  //     attributes: []
  //   }
  // }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const openNotificationWithIcon = useCallback(
    (config) => {
      api.open(config);
    },
    [api]
  );

  const fetchData = useCallback(
    async (mlb) => {
      const data = await fetch(
        "https://api.mercadolibre.com/categories/" +
        mlb.toUpperCase() +
        "?withAttributes=true"
      )
        .then((response) => {
          // Tratamento de erro de categoria não encontrada
          if (response.status === 404) {
            console.error(
              "FetchError ExportMultipleCategories response 404",
              response
            );
            openNotificationWithIcon({
              message: "Não Encontrada",
              description: "A categoria não foi encontrada pelo Mercado Livre",
              duration: 3,
              style: {
                backgroundColor: "#ff6f72",
              },
            });

            return;
          }
          return response.json();
        })
        .then((data) => {
          if (!data) return;
          const path = data.path_from_root.map((c) => c.name).join("/");
          const categoryInfo = { name: data.name, mlb: data.id, path };
          const list = data.attributes.map((attribute) => {
            return {
              id: attribute.id,
              name: attribute.name,
              relevance: attribute.relevance,
              hint: attribute.hint || " - ",
              values: attribute.values,
              tags: attribute.tags,
            };
          });

          return { info: categoryInfo, attributes: list };
        })
        .catch((err) => {
          console.error("FetchError on ExportMultipleCategories", err);
          setLoading(false);
        });
      setLoading(false);

      return data;
    },
    [openNotificationWithIcon]
  );

  const fetchMlbsData = useCallback(async () => {
    if (!mlbs || mlbs.length === 0) return;
    const removeMlb = mlbs;
    mlbs.forEach((mlb, index) => {
      if (
        !!Object.keys(categories) &&
        !!mlb &&
        !Object.keys(categories).includes(mlb)
      ) {
        fetchData(mlb).then((mlbData) => {
          if (!mlbData) {
            setMlbs(removeMlb.filter((m) => !(m === mlb)));
          }
          if (mlbData) {
            setCategories({ ...categories, [mlb]: mlbData });
          }
        });
      }
    });
    setLoading(false);
  }, [mlbs, categories, fetchData]);

  // vai disparar assim que houver alguma alteração no state de MLBs
  useEffect(() => {
    setLoading(true);
    fetchMlbsData();
  }, [mlbs, fetchMlbsData]);

  return (
    <>
      {contextHolder}
      <Button type="primary" onClick={showModal}>
        Exportar Múltiplas Categorias
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={"60vw"}
        maskClosable
        footer={false}
      >
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="Selecione as categorias"
          onChange={setMlbs}
          value={mlbs}
          options={categoriesList}
          style={{ width: "50vw" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />

        <Space style={{ marginTop: 15 }}>
          {!!categories && !loading && (
            <ExportCategoriesAttributes excelData={categories} />
          )}
          {!!categories && !loading && (
            <ExportExampleProducts excelData={categories} />
          )}
        </Space>
      </Modal>
    </>
  );
};

export default ExportMultipleCategories;
