import React from "react";
import { Content } from "antd/es/layout/layout";

import KibanaShortcuts from "./KibanaShortcuts";
import SingleFileProjects from "./SingleFileProjects";

class LinksAndShortcuts extends React.Component {
  render() {
    return (
      <Content
        id="links_and_shortcuts"
        style={{ padding: "20px 16px", minHeight: "calc(100vh - 64px)" }}
      >
        {/* <Alert
          message="Em Breve"
          description="Novos atalhos e funcionalidades estão sendo desenvolvidos, em breve teremos novidades"
          type="info"
          showIcon
          style={{ marginBottom: 8 }}
        /> */}
        <KibanaShortcuts />
        
        <SingleFileProjects />
      </Content>
    );
  }
}

export default LinksAndShortcuts;
