import React from "react";

import ExportExcel from "../../../../../Components/ExportExcel";

import tagsMapping from "../CategoryAttributes/tagsMapping.json";

function arrayFlatenner(array, value, separator) {
  // needs the array and the value to extract
  // separator is optional, default is ' / '
  let result = "";

  if (!array || array.lenght === 0) return " - ";

  array.forEach((curr, index) => {
    result =
      index === 0 ? curr[value] : separator || result + " / " + curr[value];
  });

  return result;
}

//Traduz e concatena as tags
function tagsTranslation(filter) {
  const tagsInTagsMapping = Object.keys(tagsMapping);
  let tags = "";
  filter.forEach((curr, index, arr) => {
    const includesTag = tagsInTagsMapping.includes(curr);
    const currentTag = includesTag ? tagsMapping[curr].translation : curr;
    tags = index === 0 ? currentTag : tags + " - " + currentTag;
  });
  return tags;
}

const ExportCategoryAttributes = ({ excelData }) => {
  const organizedData = excelData.attributes.map((curr, index, arr) => {
    return {
      code: curr.id,
      name: curr.name,
      relevance: curr.relevance,
      hint: curr.hint,
      values: arrayFlatenner(curr.values, "name"),
      tags: tagsTranslation(curr.tags),
    };
  });

  const columns = [
    { label: "Código", value: "code" },
    { label: "Nome", value: "name" },
    { label: "Relevância", value: "relevance" },
    { label: "Tags", value: "tags" },
    { label: "Dica/Descrição", value: "hint" },
    { label: "Valores", value: "values" },
  ];

  const tagsColumns = Object.keys(tagsMapping).map((tagKey) => {
    const { description, translation } = tagsMapping[tagKey];
    return { tag: tagKey, description, translation };
  });

  const data = [
    {
      sheet: "(" + excelData.info.mlb + ")",
      columns,
      content: organizedData,
    },
    {
      sheet: "Tags",
      columns: [
        { label: "Código da Tag", value: "tag" },
        { label: "Tag", value: "translation" },
        { label: "Descrição", value: "description" },
      ],
      content: tagsColumns,
    },
  ];

  return (
    <ExportExcel
      buttonText={"Exportar Atributos da Categoria"}
      excelData={data}
      fileName={"AtributosDaCategoria"}
    />
  );
};

export default ExportCategoryAttributes;
