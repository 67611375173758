import React from "react";

import ExportExcel from "../../../../../../Components/ExportExcel";

import tagsMapping from "../../CategoryAttributes/tagsMapping.json";
import example_product from "../../ExportExampleProduct/example_product_columns.json";

//Traduz e concatena as tags
function tagsTranslation(filter) {
  const tagsInTagsMapping = Object.keys(tagsMapping);
  let tags = "";
  filter.forEach((curr, index, arr) => {
    const includesTag = tagsInTagsMapping.includes(curr);
    const currentTag = includesTag ? tagsMapping[curr].translation : curr;
    tags = index === 0 ? currentTag : tags + " - " + currentTag;
  });
  return tags;
}

const ExportExampleProducts = ({ excelData }) => {
  let columns = example_product;
  let valueColumns = [{ label: "Categoria", value: "category" }];

  if (!excelData) return;
  const products = [];
  const attributes = [];

  Object.keys(excelData).forEach((key) => {
    const category = excelData[key];
    if (!category) return;

    const categoryName = category.info.name + " (" + category.info.mlb + ")";

    const organizedData = category.attributes
      .map((curr, index, arr) => {
        return {
          id: curr.id,
          name: curr.name,
          tags: tagsTranslation(Object.keys(curr.tags)),
        };
      })
      .filter(({ tags }) => !tags.includes("Não editável"))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    const example = organizedData
      .filter(
        (att) => !["GTIN", "SELLER_SKU" /*, 'MODEL', 'BRAND'*/].includes(att.id)
      )
      .reduce(
        (acc, cur, index) => {
          if (index + 1 >= (columns.length - 50) / 2) {
            columns = [
              ...columns,
              {
                label: "NOME " + (index + 1),
                value: "attribute_" + (index + 1),
              },
              { label: "VALOR " + (index + 1), value: "value_" + (index + 1) },
            ];
          }
          const attributeKey = "attribute_" + (index + 1);
          const valueKey = "value_" + (index + 1);
          return { ...acc, [attributeKey]: cur.name, [valueKey]: cur.values };
        },
        {
          status_product: "Ativo",
          category:
            categoryName + " - substituir pelo nome da categoria no ANY",
          sku: "sku_de_exemplo " + category.info.mlb,
          origin: 0,
          title: "Título do ptoduto teste para a categoria: " + categoryName,
          sku_title: "Título do sku teste para a categoria: " + categoryName,
          warranty: 1,
          price_type: "Automático - Custo",
          cost: 99999,
          quantity: 99,
          crossdocking: 99,
        }
      );

    const attributeList = organizedData.reduce(
      (acc, cur, index) => {
        if (index + 1 >= valueColumns.length) {
          valueColumns = [
            ...valueColumns,
            { label: "NOME " + (index + 1), value: "attribute_" + (index + 1) },
          ];
        }
        const attributeKey = "attribute_" + (index + 1);
        return { ...acc, [attributeKey]: cur.name };
      },
      {
        category: "(" + category.info.mlb + ") - " + category.info.path,
      }
    );

    attributes.push(attributeList);
    products.push(example);
  });

  const data = [
    {
      sheet: "Produtos Exemplo",
      columns,
      content: products,
    },
    {
      sheet: "Atributos por categoria",
      columns: valueColumns,
      content: attributes,
    },
  ];

  return (
    <ExportExcel
      buttonText={"Exportar Produto Exemplo"}
      excelData={data}
      fileName={"ProdutoExemplo"}
    />
  );
};

export default ExportExampleProducts;
