import React from "react";

import { Button, Card, Space } from "antd";

const baseKibanaUrlStart = `http://10.119.52.202:5601/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:60000),time:(from:now-10m,to:now))&_a=(columns:!(),filters:!(),index:`;
const baseKibanaUrlEnd = `,interval:auto,query:(language:lucene,query:''),sort:!('@timestamp',desc))`;

function clickHandler(index_key) {
  let index = "";

  const date = new Date();
  const year = date.getFullYear();
  const monthNumber =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const dayNumber = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  const indexday = year + "." + monthNumber + "." + dayNumber;
  const indexMonth = year + "." + monthNumber + ".*";

  switch (index_key) {
    case "output_month":
      index = "'requestlog-output-" + indexMonth + "'";
      break;
    case "output_day":
      index = "requestlog-output-" + indexday;
      break;
    case "input_v2oi_month":
      index = "'requestlog-input-v2oi-" + indexMonth + "'";
      break;
    case "input_v2oi_day":
      index = "requestlog-input-v2oi-" + indexday;
      break;
    case "input_month":
      index = "'requestlog-input-api-v2-" + indexMonth + "'";
      break;
    case "input_day":
      index = "requestlog-input-api-v2-" + indexday;
      break;
    case "input_freight_month":
      index = "'requestlog-input-freight-" + indexMonth + "'";
      break;
    case "input_freight_day":
      index = "requestlog-input-freight-" + indexday;
      break;
    case "logstash_month":
      index = "'logstash-v2" + indexMonth + "'";
      break;
    case "logstash_day":
      index = "logstash-v2" + indexday + "";
      break;

    default:
      index = "requestlog-output-" + indexday;
      break;
  }

  const url = baseKibanaUrlStart + index + baseKibanaUrlEnd;
  window.open(url, "_blank", "noopener,noreferrer");
}

const KibanaShortcuts = () => {
  const date = new Date();
  const monthNumber =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const month = months[date.getMonth()];
  const dayNumber = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const day = dayNumber + "/" + monthNumber;

  return (
    <Card bodyStyle={spaceStyle} title="Links Kibana">
      <Space direction="vertical" style={spaceStyle}>
        <Button onClick={() => clickHandler("output_month")} id="output_month">
          Output {month}
        </Button>
        <Button onClick={() => clickHandler("output_day")} id="output_day">
          Output {day}
        </Button>
      </Space>
      <Space direction="vertical" style={spaceStyle}>
        <Button onClick={() => clickHandler("input_month")} id="input_month">
          Input {month}
        </Button>
        <Button onClick={() => clickHandler("input_day")} id="input_day">
          Input {day}
        </Button>
      </Space>
      <Space direction="vertical" style={spaceStyle}>
        <Button
          onClick={() => clickHandler("input_v2oi_month")}
          id="input_v2oi_month"
        >
          Input_v2oi {month}
        </Button>
        <Button
          onClick={() => clickHandler("input_v2oi_day")}
          id="input_v2oi_day"
        >
          Input_v2oi {day}
        </Button>
      </Space>
      <Space direction="vertical" style={spaceStyle}>
        <Button
          onClick={() => clickHandler("logstash_month")}
          id="logstash_month"
        >
          Logstash {month}
        </Button>
        <Button onClick={() => clickHandler("logstash_day")} id="logstash_day">
          Logstash {day}
        </Button>
      </Space>
      <Space direction="vertical" style={spaceStyle}>
        <Button
          onClick={() => clickHandler("input_freight_month")}
          id="input_freight_month"
        >
          Input Freight {month}
        </Button>
        <Button
          onClick={() => clickHandler("input_freight_day")}
          id="input_freight_day"
        >
          Input Freight {day}
        </Button>
      </Space>
    </Card>
  );
};

export default KibanaShortcuts;

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const spaceStyle = { display: "flex", justifyContent: "space-between" };
