import React, { useState } from "react";
import xlsxParser from "xlsx-parse-json";
import { Button, Upload, Space, Input, List, Alert, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";

import ExportSpreadsheet from "./ExportSpreadsheet";

const SpreadsheetSplitter = () => {
  const [spreadsheet, setSpreadsheet] = useState([]);
  const [loading, setLoading] = useState([]);
  const [splitted, setSplitted] = useState([]);
  const [showUpload, setShowUpload] = useState(true);

  const handleUpload = (file) => {
    setLoading(true);
    xlsxParser.onFileSelection(file).then((data) => {
      if (!data) return;
      const firstKey = Object.keys(data)[0];
      setSpreadsheet(data[firstKey]);
      setLoading(false);
      setShowUpload(false);
    });
    return "spreadsheetsplitter";
  };

  const handleSplit = (lines) => {
    setLoading(true);
    if (!spreadsheet || spreadsheet.length === 0) return;
    const numOfSheets = spreadsheet.length / lines;
    const splits = [];
    for (let i = 0; i < numOfSheets; i++) {
      const split = spreadsheet.slice(i * lines, lines * (i + 1));
      splits.push(split);
    }
    setSplitted(splits);
    setLoading(false);
  };

  return (
    <Content
      id="spreadsheet_splitter"
      style={{ padding: "20px 16px", minHeight: "calc(100vh - 64px)" }}
    >
      <Alert
        type="warning"
        message="Pode Apresentar Lentidão"
        description="Esta ferramenta utiliza o processamento do navegador para dividir a planilha, podendo apresentar congelamento da aba por alguns segundos. Em breve teremos melhorias para a performance da mesma :)"
        style={{ marginBottom: 8 }}
        closable
        showIcon
      />

      {!showUpload && (
        <Button
          onClick={() => setShowUpload(true)}
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
        >
          Novo Arquivo
        </Button>
      )}

      {showUpload && (
        <Upload.Dragger
          action={handleUpload}
          multiple={false}
          onRemove={() => setSpreadsheet([])}
        >
          <>
            <p>Selecione o arquivo a ser dividido (apenas .xlsx)</p>
            <Button icon={<PaperClipOutlined />} loading={loading}>
              Buscar Arquivo
            </Button>
          </>
        </Upload.Dragger>
      )}

      <Space
        direction="horizontal"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "8px 0px",
        }}
      >
        {spreadsheet.length > 0 && (
          <Typography>{spreadsheet.length} linhas</Typography>
        )}
        <Input
          id="lines"
          type="number"
          defaultValue={1000}
          placeholder="Número de linhas para a divisão"
          onSubmit={handleSplit}
        />
        <Button
          disabled={!spreadsheet || spreadsheet.length === 0}
          onClick={() => handleSplit(document.getElementById("lines").value)}
        >
          Dividir
        </Button>
      </Space>

      {!!splitted && splitted.length > 0 && (
        <Space
          direction="vertical"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "8px 0px",
            padding: "0px 16px",
          }}
        >
          {/* {splitted.map((split, index) => {
            return (
              <Typography.Text key={"split_list_" + index}>
                {"Arquivo_" + (index + 1) + "_de_" + splitted.length}
                <ExportSpreadsheet
                  excelData={split}
                  fileName={"Arquivo_" + (index + 1) + "_de_" + splitted.length}
                />
              </Typography.Text>
            );
          })} */}

          <List
            pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}
            dataSource={splitted}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <ExportSpreadsheet
                    excelData={item}
                    fileName={
                      "Arquivo_" + (index + 1) + "_de_" + splitted.length
                    }
                  />,
                ]}
              >
                <List.Item.Meta
                  title={"Arquivo_" + (index + 1) + "_de_" + splitted.length}
                  description={item.length + " linhas"}
                />
              </List.Item>
            )}
          />
        </Space>
      )}
    </Content>
  );
};

export default SpreadsheetSplitter;
