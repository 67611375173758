import React from "react";
import { Table, Tag, Tooltip, Space, Empty } from "antd";

import ExportCategoryAttributes from "../ExportCategoryAttributes";
import ExportExampleProduct from "../ExportExampleProduct";

import tagsTranslation from "./tagsMapping.json";

const CategoryAttributes = ({ category, loading }) => {
  if (loading || !category)
    return (
      <>
        <Empty
          description="Selecione uma categoria"
          style={{ margin: "20px" }}
        />
      </>
    );

  const categoryInfo = { name: category.name, mlb: category.id };
  const list = category.attributes.map((attribute) => {
    return {
      id: attribute.id,
      name: attribute.name,
      relevance: attribute.relevance,
      hint:
        attribute.id === "GTIN" ? (
          <p>
            {attribute.hint + " " || " - "}
            <a
              href="https://www.mercadolivre.com.br/validador-codigos-universais"
              target="_blank"
              rel="noreferrer"
            >
              validador
            </a>
          </p>
        ) : (
          attribute.hint || " - "
        ),
      values: attribute.values,
      tags: Object.keys(attribute.tags),
    };
  });

  const tagsFilter = category.attributes
    .map((attribute) => Object.keys(attribute.tags))
    .flat()
    .filter((item, pos, arr) => arr.indexOf(item) === pos)
    .map((tag) => {
      const tagInfo = tagsTranslation[tag];
      if (tagInfo)
        return { text: tagsTranslation[tag].translation, value: tag };
      return { text: tag, value: tag };
    })
    .sort((a, b) => {
      if (a.text < b.text) return -1;
      else return 1;
    });

  const categoryData = { info: categoryInfo, attributes: list };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sortDirections: ["descend"],
      sorter: (a, b) => a.name < b.name,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      filters: tagsFilter || [],
      onFilter: (value, record) => {
        if (!record.tags || record.tags.length === 0) {
          return false;
        }
        return record["tags"].includes(value);
      },
      render: (_, obj) => {
        const tags = obj.tags;
        if (tags.length === 0) return <> - </>;
        return tags.map((key, index) => {
          if (Object.keys(tagsTranslation).includes(key)) {
            return (
              <Tooltip title={tagsTranslation[key].description}>
                <Tag
                  key={key + "_" + index + "_" + obj.id}
                  loading={loading.toString()}
                  style={{ marginTop: 2, marginBottom: 2 }}
                  color={key.includes("required") ? "red" : "green"}
                >
                  {tagsTranslation[key].translation}
                </Tag>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="Tag Não Mapeada">
                <Tag
                  key={key + "_" + index + "_" + obj.id}
                  loading={loading.toString()}
                  style={{ marginTop: 2, marginBottom: 2 }}
                  color="green"
                >
                  {key}
                </Tag>
              </Tooltip>
            );
          }
        });
      },
    },
    {
      title: "Dica/Descrição",
      dataIndex: "hint",
      key: "hint",
    },
    {
      title: "Valores",
      dataIndex: "values",
      key: "values",
      render: (_, obj) => {
        const values = obj.values;
        if (!values) return <></>;
        return (
          <>
            {values.map((value, ind) => {
              return (
                <Tag
                  key={value.id + "_" + ind + "_" + obj.id}
                  loading={loading.toString()}
                  style={{ marginTop: 2, marginBottom: 2 }}
                >
                  {value.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <Table
      showSorterTooltip
      pagination={{ showSizeChanger: true }}
      columns={columns}
      dataSource={categoryData.attributes}
      loading={loading.toString()}
      footer={() => {
        return (
          <Space>
            <ExportCategoryAttributes excelData={categoryData} />
            <ExportExampleProduct excelData={categoryData} />
          </Space>
        );
      }}
    />
  );
};

export default CategoryAttributes;
