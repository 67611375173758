import React from "react";
import { Input, Space, Button } from "antd";
import { Content } from "antd/es/layout/layout";

//Formatter - first splits the string and then concats "," adding " to both beginning and end of the string
function Format() {
  let separator = document.getElementById("separator").value || `','`;
  let formatted =
    separator.substr(0, 1) +
    document
      .getElementById("text_format_input")
      .value.split("\n")
      .join(separator) +
    separator.substr(-1, 1);
  document.getElementById("text_format_output").innerHTML = formatted;
}

function Copy() {
  //Copy to Clipboard - Copies the value on formatted_orders to clipboard
  navigator.clipboard.writeText(
    document.getElementById("text_format_output").value
  );
}

function Clear() {
  //Clear Fields- Clears all fields
  document.getElementById("text_format_input").value = "";
  document.getElementById("text_format_output").value = "";
  document.getElementById("separator").value = "";
}

class TextConcatenator extends React.Component {
  render() {
    return (
      <Content
        id="text_concatener"
        style={{ padding: "20px 16px", minHeight: "calc(100vh - 64px)" }}
      >
        <Input.TextArea
          id="text_format_input"
          style={{ height: "40vh" }}
          placeholder="Insira os valores a serem concatenados"
        />
        <Space
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "8px 0px",
          }}
        >
          <Button onClick={() => Clear()}>Limpar Campos</Button>
          <Button onClick={() => Format()}>Formatar</Button>
          <Button onClick={() => Copy()}>Copiar Resultado</Button>
          <Input id="separator" placeholder="texto a ser concatenado" />
        </Space>
        <Input.TextArea
          id="text_format_output"
          style={{ height: "40vh" }}
          placeholder="Os valores concatenados serão exibidos aqui"
        />
      </Content>
    );
  }
}

export default TextConcatenator;
