import React from "react";

import ExportExcel from "../../../../../Components/ExportExcel";

import tagsMapping from "../CategoryAttributes/tagsMapping.json";
import example_product from "./example_product_columns.json";

function arrayFlatenner(array, value, separator) {
  // needs the array and the value to extract
  // separator is optional, default is ' / '
  let result = "";

  if (!array || array.lenght === 0) return " - ";

  array.forEach((curr, index) => {
    result =
      index === 0 ? curr[value] : separator || result + " / " + curr[value];
  });

  return result;
}

//Traduz e concatena as tags
function tagsTranslation(filter) {
  const tagsInTagsMapping = Object.keys(tagsMapping);
  let tags = "";
  filter.forEach((curr, index, arr) => {
    const includesTag = tagsInTagsMapping.includes(curr);
    const currentTag = includesTag ? tagsMapping[curr].translation : curr;
    tags = index === 0 ? currentTag : tags + " - " + currentTag;
  });
  return tags;
}

const ExportExampleProduct = ({ excelData }) => {
  let columns = example_product;

  const categoryName = excelData.info.name + " (" + excelData.info.mlb + ")";
  const organizedData = excelData.attributes
    .map((curr, index, arr) => {
      return {
        id: curr.id,
        name: curr.name,
        relevance: curr.relevance,
        hint: curr.hint,
        values: arrayFlatenner(curr.values, "name"),
        tags: tagsTranslation(curr.tags),
      };
    })
    .filter(({ tags }) => !tags.includes("Não editável"));

  const example = organizedData
    .filter(
      (att) => !["GTIN", "SELLER_SKU" /*, 'MODEL', 'BRAND'*/].includes(att.id)
    )
    .reduce(
      (acc, cur, index) => {
        if (index + 1 >= (columns.length - 50) / 2) {
          columns = [
            ...columns,
            {
              label: "NOME " + (index + 1),
              value: "attribute_" + (index + 1),
            },
            { label: "VALOR " + (index + 1), value: "value_" + (index + 1) },
          ];
        }
        const attributeKey = "attribute_" + (index + 1);
        const valueKey = "value_" + (index + 1);
        return { ...acc, [attributeKey]: cur.name, [valueKey]: cur.values };
      },
      {
        status_product: "Ativo",
        category: categoryName + " - substituir pelo nome da categoria no ANY",
        sku: "sku_de_exemplo_" + excelData.info.mlb,
        origin: 0,
        title: "Título do ptoduto teste para a categoria: " + categoryName,
        sku_title: "Título do sku teste para a categoria: " + categoryName,
        warranty: 1,
        price_type: "Automático - Custo",
        cost: 99999,
        quantity: 99,
        crossdocking: 99,
      }
    );

  const data = [
    {
      sheet: "Produtos Exemplo",
      columns,
      content: [example],
    },
  ];

  return (
    <ExportExcel
      buttonText={"Exportar Produto Exemplo"}
      excelData={data}
      fileName={"ProdutoExemplo"}
    />
  );
};

export default ExportExampleProduct;
