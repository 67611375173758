import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { Header } from "antd/es/layout/layout";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // filtra apenas as duas primeiras partes do link
  const currentLocation = location.pathname.split("/").slice(0, 3).join("/");

  return (
    <Header style={{ padding: 0 }}>
      <Menu
        theme="light"
        mode="horizontal"
        onSelect={(a) => navigate(a.key)}
        style={{ paddingLeft: 8 }}
        selectedKeys={[currentLocation]}
        items={[
          { key: "/tools/meli", label: "Buscador de Categorias" },
          // {
          //   key: "/tools/orderupdate",
          //   label: "Atualizador de Pedidos",
          // },
          {
            key: "/tools/spreadsheetsplitter",
            label: "Dividir Planilhas",
          },
          {
            key: "/tools/textconcatenator",
            label: "Concatenador de textos",
          },
          {
            key: "/tools/linksandshortcuts",
            label: "Links e Atalhos",
          },
        ]}
      />
    </Header>
  );
};

export default HeaderMenu;
