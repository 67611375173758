import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout, Typography } from "antd";
import { Content, Footer } from "antd/es/layout/layout";

import Home from "./Pages/Home";
import HeaderMenu from "./Components/Header";
import CategoriesListMaker from "./Pages/CategoriesListMaker";
import MeliCategory from "./Pages/Tools/MeliCategory";
// import AnyOrderUpdate from "./Pages/Tools/AnyOrderUpdate";
import TextConcatenator from "./Pages/Tools/TextConcatenator";
import LinksAndShortcuts from "./Pages/Tools/LinksAndShortcuts";
import SpreadsheetSplitter from "./Pages/Tools/SpreadsheetSplitter";

import "./App.css";

function App() {
  return (
    <Layout className="App">
      <HeaderMenu />
      <Content>
        <Routes>
          <Route path="/">
            <Route exact path="/" element={<Home />} />

            <Route
              exact
              path="/category_list_maker"
              element={<CategoriesListMaker />}
            />

            <Route exact path="/tools/meli" element={<MeliCategory />} />

            <Route exact path="/tools/meli/:id" element={<MeliCategory />} />

            <Route
              exact
              path="/tools/textconcatenator"
              element={<TextConcatenator />}
            />

            <Route
              exact
              path="/tools/linksandshortcuts"
              element={<LinksAndShortcuts />}
            />

            {/* <Route
              exact
              path="/tools/orderupdate"
              element={<AnyOrderUpdate />}
            /> */}

            <Route
              exact
              path="/tools/spreadsheetsplitter"
              element={<SpreadsheetSplitter />}
            />
          </Route>
        </Routes>
      </Content>
      <Footer style={{fontSize: 4, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Typography.Link style={{fontSize: 10, cursor: "pointer"}} rel="noopener noreferrer" target="_blank" href="https://github.com/victor-reghini">
          Developed by bigode
        </Typography.Link>
      </Footer>
    </Layout>
  );
}

export default App;
