import React from "react";

// import categories from "./categoriesMLU.json";

class CategoriesListMaker extends React.Component {
  // curl https://api.mercadolibre.com/sites/MLB/categories/all  > categoriesMLB.gz
  // Executar o Curl no git bash
  // será gerado um arquivo compactado
  // Descompactar o arquivo e salvar o arquivo como json
  // Colocar o arquivo neste diretório e descomentar o código
  // Acessar o link http://localhost:3000/category_list_maker
  // Copiar o conteúdo com Ctrl + A e colar no arquivo em:
  // \src\Pages\Tools\MeliCategory\CategoriesComponents\CategorySearchBar\categoriesMLB.json

  // MLA: "Argentina" curl https://api.mercadolibre.com/sites/MLA/categories/all  > categoriesMLA.gz
  // MLB: "Brasil"    curl https://api.mercadolibre.com/sites/MLB/categories/all  > categoriesMLB.gz
  // MLC: "Chile"     curl https://api.mercadolibre.com/sites/MLC/categories/all  > categoriesMLC.gz
  // MCO: "Colômbia"  curl https://api.mercadolibre.com/sites/MCO/categories/all  > categoriesMCO.gz
  // MLM: "México"    curl https://api.mercadolibre.com/sites/MLM/categories/all  > categoriesMLM.gz
  // MPE: "Peru"      curl https://api.mercadolibre.com/sites/MPE/categories/all  > categoriesMPE.gz
  // MLU: "Uruguai"   curl https://api.mercadolibre.com/sites/MLU/categories/all  > categoriesMLU.gz

  render() {
    return <></>;
    // return (
    //   <div className="landing_page">
    //     {JSON.stringify(
    //       Object.keys(categories).map((key) => {
    //         const category = categories[key];
    //         return {
    //           value: category.id,
    //           label: category.path_from_root.map((c) => c.name).join("/"),
    //           path: category.path_from_root.map((c) => ({
    //             label: c.name,
    //             value: c.id,
    //           })),
    //         };
    //       })
    //     )}
    //   </div>
    // );
  }
}

export default CategoriesListMaker;
