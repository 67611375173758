import React from "react";
import ExportExcel from "../../../../Components/ExportExcel";

const ExportSpreadsheet = ({ excelData, fileName }) => {
  if (!excelData) return;
  const columns = Object.keys(excelData[0]).map((key) => ({
    label: key,
    value: key,
  }));

  const data = [
    {
      sheet: fileName,
      columns,
      content: excelData,
    },
  ];

  return (
    <ExportExcel buttonText={"Download"} excelData={data} fileName={fileName} />
  );
};

export default ExportSpreadsheet;
