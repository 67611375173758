import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Tabs, Card, notification, Empty } from "antd";
import { Content } from "antd/es/layout/layout";

// import RootCategories from "./CategoriesComponents/RootCategories";
import CountrySelect from "./CategoriesComponents/CountrySelect";
import CategoryExplorer from "./CategoriesComponents/CategoryExplorer";
import CategoryNavegation from "./CategoriesComponents/CategoryNavegation";
import CategoryAttributes from "./CategoriesComponents/CategoryAttributes";
import CategoryInfo from "./CategoriesComponents/CategoryInfo";
import CategorySearchBar from "./CategoriesComponents/CategorySearchBar";
import ExportMultipleCategories from "./CategoriesComponents/ExportMultipleCategories";

import gif from "../../../images/notfound.gif";
import { Loading3QuartersOutlined } from "@ant-design/icons";

import "./index.css";

const MeliCategory = () => {
  const [api, contextHolder] = notification.useNotification();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const mlb = location.pathname.split("/")[3];

  const openNotificationWithIcon = useCallback(
    (config) => {
      api.open(config);
    },
    [api]
  );

  const fetchData = useCallback(async () => {
    if (!mlb) {
      setLoading(false);
      return;
    }
    const data = await fetch(
      "https://api.mercadolibre.com/categories/" +
        mlb.toUpperCase() +
        "?withAttributes=true"
    )
      .then((response) => {
        // Tratamento de erro de categoria não encontrada
        if (response.status === 404) {
          console.error("FetchError MeliCategory response 404", response);
          if (mlb !== "") {
            openNotificationWithIcon({
              message: "Não Encontrada",
              description: "A categoria não foi encontrada pelo Mercado Livre",
              duration: 3,
              style: {
                backgroundColor: "#ff6f72",
              },
            });
          }
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (!data) return;
        return data;
      })
      .catch((err) => {
        if (err.status === 404) {
          window.alert("categoria não encontrada");
          navigate("/tools/meli");
        }

        console.error("FetchError at MeliCategory", err);
      });

    setCategory(data);
    setLoading(false);
  }, [mlb, openNotificationWithIcon, navigate]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [mlb, fetchData]);

  return (
    <>
      {contextHolder}
      <Content
        id="meli_category_browser"
        style={{ padding: "20px 16px", minHeight: "calc(100vh - 64px)" }}
      >
        <Space>
          <Space.Compact block>
            <CountrySelect />
          </Space.Compact>

          <Space.Compact block style={{ maxWidth: "40%" }}>
            <CategoryExplorer />
          </Space.Compact>

          {/* <Space.Compact>
            <RootCategories />
          </Space.Compact> */}

          <Space.Compact block style={{ maxWidth: "50%" }}>
            <CategorySearchBar />
          </Space.Compact>

          <Space.Compact block style={{ width: "10%" }}>
            <ExportMultipleCategories />
          </Space.Compact>
        </Space>

        <CategoryNavegation category={category} />

        <Card
          bodyStyle={{ padding: 0 }}
          style={{
            marginTop: 15,
            minHeight: "calc(100vh - 200px)",
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        >
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: 50,
              }}
            >
              <Loading3QuartersOutlined spin />
            </div>
          ) : !category ? (
            <Empty
              description={
                !!mlb
                  ? "Categoria não encontrada no Marketplace :("
                  : "Selecione uma categoria"
              }
              style={{
                marginTop: "20vh",
                alignSelf: "center",
              }}
              image={!!mlb ? gif : Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <Tabs
              type="card"
              tabBarStyle={{ margin: 0 }}
              bodyStyle={{ backgroundColor: "green" }}
              items={[
                {
                  label: `Atributos`,
                  key: "attributes",
                  children: (
                    <CategoryAttributes category={category} loading={loading} />
                  ),
                },
                {
                  label: `Sobre`,
                  key: "about",
                  children: (
                    <CategoryInfo category={category} loading={loading} />
                  ),
                },
              ]}
            />
          )}
        </Card>
      </Content>
    </>
  );
};

export default MeliCategory;
