import React from "react";
import { Button } from "antd";
import xlsx from "json-as-xlsx";

import { DownloadOutlined } from "@ant-design/icons";

const ExportExcel = ({ excelData, fileName, buttonText }) => {
  const exportToExcel = async () => {
    let settings = {
      fileName,
      extraLength: 5, // colunas extras
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    };

    xlsx(excelData, settings);
  };

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={(e) => exportToExcel(fileName)}
    >
      {buttonText}
    </Button>
  );
};

export default ExportExcel;
