import React, { useEffect } from "react";
import { Select } from "antd";

const CountrySelect = () => {
  const country = localStorage.getItem("country");

  const handleChange = (value) => {
    localStorage.setItem("country", value);
    window.location.reload();
  };

  useEffect(() => {
    if (!localStorage.getItem("country")) {
      localStorage.setItem("country", "MLB");
      window.location.reload();
    }
  }, []);

  return (
    <Select
      onChange={handleChange}
      defaultValue={country}
      dropdownStyle={{ minWidth: 120 }}
      showArrow={false}
      options={[
        { value: "MLA", label: "Argentina" },
        { value: "MLB", label: "Brasil " },
        { value: "MLC", label: "Chile" },
        { value: "MCO", label: "Colômbia" },
        { value: "MLM", label: "México" },
        { value: "MPE", label: "Peru" },
        { value: "MLU", label: "Uruguai " },
      ]}
    />
  );
};

export default CountrySelect;
