import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Tooltip } from "antd";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import Title from "antd/es/typography/Title";

import "./index.css";

const CategoryNavegation = ({ category }) => {
  const navigate = useNavigate();

  if (!category) {
    return (
      <Title style={{ marginTop: 8 }} level={2}>
        Buscador de Categorias
      </Title>
    );
  }

  return (
    <div>
      {(category.path_from_root || []).length > 1 ? (
        <Breadcrumb style={{ marginTop: 12 }}>
          {category.path_from_root.map((category, index) => (
            <BreadcrumbItem
              className="link"
              onClick={() => navigate("/tools/meli/" + category.id)}
              key={category.id + "_" + index}
            >
              {category.name}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      ) : (
        <></>
      )}

      <Title
        style={{
          marginTop: (category.path_from_root || []).length > 1 ? 0 : 8,
        }}
        level={2}
      >
        {category.name}
      </Title>

      {category.children_categories && (
        <Breadcrumb separator="-">
          {category.children_categories.map((category, index) => (
            <BreadcrumbItem
              className="link"
              onClick={() => navigate("/tools/meli/" + category.id)}
              key={category.id + "_" + index}
            >
              <Tooltip
                size="small"
                title={category.total_items_in_this_category + " Anúncios"}
              >
                {category.name}
              </Tooltip>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

export default CategoryNavegation;
