import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TreeSelect, notification } from "antd";

const CategoryExplorer = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [treevalue, setTreeValue] = useState();
  const [treeData, setTreeData] = useState([]);

  const country = localStorage.getItem("country") || "MLB";

  const openNotificationWithIcon = useCallback(
    (config) => {
      api.open(config);
    },
    [api]
  );

  // Função de busca dos daśo s
  const fetchData = useCallback(
    async (url) => {
      const data = await fetch(url)
        .then((response) => {
          // Tratamento de erro de categoria não encontrada
          if (response.status === 404) {
            console.error(
              "Fetch Category Children Error on CategoryExplorer response 404",
              response
            );
            openNotificationWithIcon({
              message: "Não Encontrada",
              description: "A categoria não foi encontrada pelo Mercado Livre",
              duration: 3,
              style: {
                backgroundColor: "#ff6f72",
              },
            });
            return;
          }
          return response.json();
        })
        .then((data) => {
          if (!data) return;
          return data;
        })
        .catch((err) => {
          if (err.status === 404) {
            window.alert("categorias não encontradas");
            navigate("/");
          }
  
          console.error("FetchError at CategoryExplorer", err);
        });

      return data;
    },
    [openNotificationWithIcon, navigate]
  );

  // Carregamento de categorias raiz
  const loadRootCategories = useCallback(async () => {
    const url = "https://api.mercadolibre.com/sites/" + country + "/categories";
    const newData = await fetchData(url).then((data) =>
      data.map((category) => {
        return {
          id: category.id,
          pId: 0,
          value: category.id,
          label: category.name,
        };
      })
    );
    setTreeData(newData);
  }, [fetchData, country]);

  // Carregamento de subcategorias
  const onLoadData = async ({ value }) => {
    const url =
      "https://api.mercadolibre.com/categories/" + value.toUpperCase();
    const newData = await fetchData(url).then((data) => {
      if (!data) return;
      const parent_category = data.id;
      const children_categories = data.children_categories.map((category) => {
        return {
          id: category.id,
          pId: parent_category,
          value: category.id,
          label: category.name,
        };
      });

      return children_categories;
    });
    setTreeData(treeData.concat(newData));
  };

  // Redireciona para a página da categoria
  const onChange = (value) => {
    setTreeValue(value);
    navigate("/tools/meli/" + value);
  };

  // Carrega as categorias raiz na primeira renderização
  useEffect(() => {
    loadRootCategories();
  }, [loadRootCategories, country]);

  return (
    <>
      {contextHolder}
      <TreeSelect
        treeDataSimpleMode
        value={treevalue}
        treeData={treeData}
        onChange={onChange}
        loadData={onLoadData}
        fieldNames={{ id: "id", pId: "pId", value: "value", title: "label" }}
        dropdownStyle={{ minWidth: 300 }}
        placeholder="Selecione uma categoria"
        treeLine
      />
    </>
  );
};

export default CategoryExplorer;
