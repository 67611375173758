import React from "react";
import { Empty, Table } from "antd";

const CategoryInfo = ({ category, loading }) => {
  if (loading || !category) {
    return (
      <Empty description="Selecione uma categoria" style={{ margin: "20px" }} />
    );
  }

  const createdDate = new Date(category.date_created);
  const categoryInfo = [
    {
      name: "Total de Itens na Categoria",
      value: category.total_items_in_this_category,
    },
    {
      name: "Data de Criação",
      value: createdDate.toLocaleString(),
    },
    {
      name: "Status da categoria",
      value:
        category.settings.status === "enabled"
          ? "Ativa"
          : category.settings.status === "disabled"
          ? "Inativa"
          : " - ",
    },
    {
      name: "Opções de envio",
      value: category.settings.shipping_options
        .map((shipping) => {
          if (shipping === "custom") return "Definido pelo Seller";
          if (shipping === "carrier") return "Definido pela Operadora";
          return shipping;
        })
        .join(" - "),
    },
    {
      name: "Limite de caracteres para o título",
      value: category.settings.max_title_length,
    },
    {
      name: "Limite de caracteres para a descrição",
      value: category.settings.max_description_length,
    },
    {
      name: "Máximo de imagens no produto",
      value: category.settings.max_pictures_per_item,
    },
    {
      name: "Máximo de imagens por variação",
      value: category.settings.max_pictures_per_item_var,
    },
    {
      name: "Máximo de variações permitidas",
      value: category.settings.max_variations_allowed,
    },
    {
      name: "Preço máximo",
      value: category.settings.maximum_price,
    },
    {
      name: "Moeda do preço máximo",
      value: category.settings.maximum_price_currency,
    },
    {
      name: "Preço mínimo",
      value: category.settings.minimum_price,
    },
    {
      name: "Moeda do preço mínimo",
      value: category.settings.minimum_price_currency,
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <>
      <Table
        showHeader={false}
        showSorterTooltip
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: 15,
        }}
        columns={columns}
        dataSource={categoryInfo}
        loading={loading}
      />
    </>
  );
};

export default CategoryInfo;
