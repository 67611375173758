import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Select } from "antd";

import categoriesMLA from "./categoriesMLA.json";
import categoriesMLB from "./categoriesMLB.json";
import categoriesMLU from "./categoriesMLU.json";
import categoriesMLC from "./categoriesMLC.json";
import categoriesMCO from "./categoriesMCO.json";
import categoriesMPE from "./categoriesMPE.json";
import categoriesMLM from "./categoriesMLM.json";

const CategorySearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const mlb = location.pathname.split("/")[3];

  const country = localStorage.getItem("country");

  let categories;

  switch (country) {
    case "MLA":
      categories = categoriesMLA;
      break;
    case "MLB":
      categories = categoriesMLB;
      break;
    case "MLU":
      categories = categoriesMLU;
      break;
    case "MLC":
      categories = categoriesMLC;
      break;
    case "MCO":
      categories = categoriesMCO;
      break;
    case "MPE":
      categories = categoriesMPE;
      break;
    case "MLM":
      categories = categoriesMLM;
      break;
    default:
      categories = categoriesMLB;
  }

  return (
    <Select
      showSearch
      placeholder="Pesquisar por uma categoria"
      onChange={(value) => navigate("/tools/meli/" + value)}
      value={mlb}
      options={categories}
      dropdownStyle={{ minWidth: "60vw" }}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
        (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
    />
  );
};

export default CategorySearchBar;
