import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "antd";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{ padding: 0, width: "40vw" }}
        bodyStyle={{ display: "flex", flexDirection: "column" }}
      >
        {[
          { key: "/tools/meli", label: "Buscador de Categorias" },
          // {
          //   key: "/tools/orderupdate",
          //   label: "Atualizador de Pedidos",
          // },
          {
            key: "/tools/spreadsheetsplitter",
            label: "Dividir Planilhas",
          },
          {
            key: "/tools/textconcatenator",
            label: "Concatenador de textos",
          },
          {
            key: "/tools/linksandshortcuts",
            label: "Links e Atalhos",
          },
        ].map((a) => {
          return (
            <Button onClick={() => navigate(a.key)} style={{ marginTop: 8 }}>
              {a.label}
            </Button>
          );
        })}
      </Card>
    </div>
  );
};

export default Home;
