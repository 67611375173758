import React from "react";

import { Button, Card } from "antd";

import links from "./links.json"

const SingleFileProjects = () => {
    return (
        <Card title="Outras Ferramentas by bigode" style={{marginTop: 8}} 
        bodyStyle={{ display: "flex", justifyContent: "space-between" }}>
            {
                links.map(link => (
                    <Button id={link.id} onClick={() => window.open(link.url, "_blank", "noopener,noreferrer")}>
                        {link.title}
                    </Button>
                ))
            }
        </Card>
    )
}

export default SingleFileProjects;