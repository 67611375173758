import React from "react";

import ExportExcel from "../../../../../../Components/ExportExcel";

import tagsMapping from "../../CategoryAttributes/tagsMapping.json";

function arrayFlatenner(array, value, separator) {
  // needs the array and the value to extract
  // separator is optional, default is ' / '
  let result = "";

  if (!array || array.lenght === 0) return " - ";

  array.forEach((curr, index) => {
    result =
      index === 0 ? curr[value] : separator || result + " / " + curr[value];
  });

  return result;
}

//Traduz e concatena as tags
function tagsTranslation(filter) {
  const tagsInTagsMapping = Object.keys(tagsMapping);
  let tags = "";
  filter.forEach((curr, index, arr) => {
    const includesTag = tagsInTagsMapping.includes(curr);
    const currentTag = includesTag ? tagsMapping[curr].translation : curr;
    tags = index === 0 ? currentTag : tags + " - " + currentTag;
  });
  return tags;
}

const ExportCategoriesAttributes = ({ excelData }) => {
  if (!excelData) return;
  const categoriesListWithAttributes = [];

  const columns = [
    { label: "Código", value: "code" },
    { label: "Nome", value: "name" },
    { label: "Relevância", value: "relevance" },
    { label: "Tags", value: "tags" },
    { label: "Dica/Descrição", value: "hint" },
    { label: "Valores", value: "values" },
  ];

  Object.keys(excelData).forEach((key) => {
    const category = excelData[key];
    if (!category) return;

    const organizedData = category.attributes.map((curr, index, arr) => {
      return {
        code: curr.id,
        name: curr.name,
        relevance: curr.relevance,
        hint: curr.hint,
        values: arrayFlatenner(curr.values, "name"),
        tags: tagsTranslation(Object.keys(curr.tags)),
      };
    });

    categoriesListWithAttributes.push({
      sheet: "(" + category.info.mlb + ")",
      columns,
      content: organizedData,
    });
  });

  const tagsColumns = Object.keys(tagsMapping).map((tagKey) => {
    const { description, translation } = tagsMapping[tagKey];
    return { tag: tagKey, description, translation };
  });

  const categoriesList = Object.keys(excelData).map((key) => {
    const { name, mlb, path } = excelData[key].info;
    return { name, mlb, path };
  });

  const data = [
    {
      sheet: "Categorias e MLBs",
      columns: [
        { label: "MLB", value: "mlb" },
        { label: "Categoria", value: "name" },
        { label: "Árvore da Categoria", value: "path" },
      ],
      content: categoriesList,
    },
    {
      sheet: "Tags",
      columns: [
        { label: "Código da Tag", value: "tag" },
        { label: "Tag", value: "translation" },
        { label: "Descrição", value: "description" },
      ],
      content: tagsColumns,
    },
  ].concat(categoriesListWithAttributes);

  return (
    <ExportExcel
      buttonText={"Exportar Atributos das Categorias"}
      excelData={data}
      fileName={"AtributosDasCategorias"}
    />
  );
};

export default ExportCategoriesAttributes;
